<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="usuarios"
      :loading="loading"
      :items-per-page="10"
      :sort-desc="true"
      class="data-tables data-tables__row-click"
      @click:row="goToUsuario"
    >
    </v-data-table>
  </v-container>
</template>

<script>
import { allLeadsUsuarios } from "@/api/admin/usuarios.js";
export default {
  name: "Leads",

  props: {
    user_id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      loading: true,
      usuarios: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "documento",
          value: "documento",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "celular",
          width: "120px",
          sortable: false,
          value: "celular",
        },
      ];
    },
  },

  methods: {
    goToUsuario(item) {
      this.$router.push({ path: `/usuarios/${item.id}` });
    },
    async getUsuarioLeads() {
      this.loading = true;
      await allLeadsUsuarios(this.user_id)
        .then((response) => {
          this.usuarios = response;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getUsuarioLeads();
  },
};
</script>

<style></style>
